import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Share from "../components/share.js"
import Widget from "../components/market-widget.js"
import TableOfContent from "../components/table-of-content-raiting.js"
import Faq from "../components/faq.js"
import Otherpages from "../components/otherpages.js"
import Footer from "../components/footer.js"
import Author from "../components/author.js"

// Картинки
import firmness from "../images/icons/firmness.svg"
import load from "../images/icons/load.svg"
import springs from "../images/icons/springs.svg"
import warranty from "../images/icons/warranty.svg"

export default function Mattress({data}) { 
  	// Оборачиваем таблицу в div 
	const addDiv = () => { 
		const el = document.querySelector('table');
		if(el) { 
			const wrapper = document.createElement('div'); 
			wrapper.classList.add('table-data-content'); 
			el.parentElement.insertBefore(wrapper, el); 
			wrapper.appendChild(el); 
		}
	};
	
	useEffect(()=>{
		addDiv(); // Оборачиваем таблицу в div 
	}, [])

	const updatedAt = data.allContentfulPagesMattress.edges.map(({ node }) => ( node.updatedAt)) // Получаем дату обновления страницы
	const title = data.allContentfulPagesMattress.edges.map(({ node }) => ( node.titlePage)) // Получаем заголовок страницы
	const titleTop = data.allContentfulPagesMattress.edges.map(({ node }) => ( node.titleTop)) // Получаем заголовок рейтинга

	const otherArticles = data.allContentfulPagesMattress.edges.map(({ node }, i) => ( node.otherArticles.map(pages => ( pages )) ))

	const faqs = data.allContentfulPagesMattress.edges.map(({ node }) => ( node))
	const accordionData = [ // Создаем массив с пунктами FAQ
	  	{
	    	title: faqs[0].faqTitle1 && faqs[0].faqTitle1,
	    	content: faqs[0].faqText1 && faqs[0].faqText1.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle2 && faqs[0].faqTitle2,
	    	content: faqs[0].faqText2 && faqs[0].faqText2.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle3 && faqs[0].faqTitle3,
	    	content: faqs[0].faqText3 && faqs[0].faqText3.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle4 && faqs[0].faqTitle4,
	    	content: faqs[0].faqText4 && faqs[0].faqText4.childMarkdownRemark.html,
	  	},
	  	{ 
	    	title: faqs[0].faqTitle5 && faqs[0].faqTitle5,
	    	content: faqs[0].faqText5 && faqs[0].faqText5.childMarkdownRemark.html,
	  	},
	  	{ 
	    	title: faqs[0].faqTitle6 && faqs[0].faqTitle6,
	    	content: faqs[0].faqText6 && faqs[0].faqText6.childMarkdownRemark.html,
	  	},
	  	{ 
	    	title: faqs[0].faqTitle7 && faqs[0].faqTitle7,
	    	content: faqs[0].faqText7 && faqs[0].faqText7.childMarkdownRemark.html,
	  	},
	]

	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timezone: 'UTC'
	};

	const dateUpdate = new Date(updatedAt[0]).toLocaleString("ru", options)

	return (
		<>	
			{data.allContentfulPagesMattress.edges.map(({ node }) => (
				<div key={Math.random()}>
					<Seo title={node.title.replace('{year}', (new Date().getFullYear())).replace('{lastYear}', (new Date().getFullYear() - 1))} description={node.description.replace('{year}', (new Date().getFullYear())).replace('{lastYear}', (new Date().getFullYear() - 1))} />
					<Helmet>
						{node.titlePage.indexOf('Рейтинг матрасов') === -1 ? (<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{ "@type": "ListItem","position": 2,"name": "Рейтинг матрасов ${(new Date().getFullYear() - 1)}-${(new Date().getFullYear())} года","item": "https://sleepadvisor.ru/reiting-matrasov/"},{"@type": "ListItem","position": 3,"name": "${node.titlePage}"}]}`}</script>) : (<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru/"},{"@type": "ListItem","position": 2,"name": "${node.titlePage}"}]}`}</script>)}
						<script type="application/ld+json">{`{"@context":"https://schema.org","@graph":[{"@type":"Article","mainEntityOfPage":{"@type":"WebPage","@id":"https://sleepadvisor.ru/${node.url}/"},"headline":"${node.titlePage}","description":"${node.description}","author":{"@type":"Person","name":"Оксана Федосова","knowsAbout":"Эксперт по матрасам и другим товарам для сна","url":"https://sleepadvisor.ru/author.jpg"},"publisher":{"@type":"Organization","name":"SleepAdvisor","logo":{"@type":"ImageObject","url":"https://sleepadvisor.ru/logo.svg"}},"datePublished":"${node.createdAt}","dateModified":"${node.updatedAt}","potentialAction":{"@type":"ReadAction","target":{"@type":"EntryPoint","urlTemplate":"https://sleepadvisor.ru/${node.url}/"}}}]}`}</script>
					</Helmet>
				</div>
			))}
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                {title[0].indexOf('Рейтинг матрасов') !== 0 && <li className="breadcrumb-item"><a href="/reiting-matrasov/">Рейтинг матрасов {(new Date().getFullYear() - 1)}-{(new Date().getFullYear())} года</a></li>}
					                <li className="breadcrumb-item active" aria-current="page">{title}</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>{title}</h1>
					<p className="date-modified font-[merriweather]">Обновлено {dateUpdate.replace(' г.', '')}</p>
				</div>
			</div>
			<section className="content py-12 max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
				<div className="container">
					{data.allContentfulPagesMattress.edges.map(({ node }) => (
						<div key={node.id} className="text-lg font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.minitext.childMarkdownRemark.html }}/>
					))}
					{/*<div id="yandex_rtb_R-A-690338-9" />*/}
					{/*<TableOfContent />*/}
					{data.allContentfulPagesMattress.edges.map(({ node }) => (
						<TableOfContent postsToRender={node} titlePage={titleTop} />
					))}
					<div className="about-item texting">
						<h2 id="top">{titleTop}</h2>
						{data.allContentfulPagesMattress.edges.map(({ node }, i) => (
							node.showList === true && (
								<ol className="top-list" key={node.id}>
									{node.mattress.map(matres =>
										<li key={matres.id}>
											<a href={matres.affilateLink ? matres.affilateLink : matres.linkPrice} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','button_price')}}>{matres.brand ? (matres.brand.name +' '+ matres.name) : (matres.name)}</a>
										</li>
									)}
								</ol>
							)
						))}
						{data.allContentfulPagesMattress.edges.map(({ node }, i) => (
							node.mattress.map(matres =>
							<div key={Math.random()}>
								<h3>{matres.brand ? (matres.brand.name +' '+ matres.name) : (matres.name)}</h3>
								<div className="items">
						            {matres.brand && <div className="title-item flex">
						                <p className="font-merriweather">{matres.name}</p>
						                <div className="logo">
						                	<GatsbyImage image={getImage(matres.brand.image)} alt={matres.brand.name} />
						                </div>
						            </div> }
						            <div className="item">
						                <GatsbyImage image={getImage(matres.image)} alt={matres.name} />
						                <div className="features">
						                	<p className="features__title">Особенности</p>
											{matres.peculiarities && (<div className="features__list" dangerouslySetInnerHTML={{ __html: matres.peculiarities.childMarkdownRemark.html }}/>)}
											{matres.url && (<a href={'/'+matres.brand.linkBrandPage+'/'+matres.url+'/'} className="more-link-model">Полный обзор</a>) }
						                </div>
						            </div>
						            {/*matres.affilateLink ? <div className="items__button"><a href={matres.affilateLink} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','aff_card')}}>Показать цену</a></div>
						            : <Widget dataId={'marketWidget'+(i++)} marketId={matres.marketId} model={matres.brand ? (matres.brand.name +' '+ matres.name) : (matres.name)} />}*/}
						            {matres.affilateLink ? <div className="items__button"><a href={matres.affilateLink} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','aff_card')}}>Показать цену</a></div> :
						        	<div className="items__button"><a href={matres.linkPrice} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','aff_card')}}>Показать цену</a></div> }

						            <div className={matres.affilateLink && 'widget-with-button' }>
						            	<Widget dataId={'marketWidget'+(i++)} marketId={matres.marketId} model={matres.brand ? (matres.brand.name +' '+ matres.name) : (matres.name)} />
						            </div>

						            <div className="text font-merriweather">
							            {node.aboutMattress1 && node.aboutMattress1.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling1" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress1.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling1" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress2 && node.aboutMattress2.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling2" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress2.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling2" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress3 && node.aboutMattress3.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling3" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress3.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling3" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress4 && node.aboutMattress4.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling4" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress4.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling4" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress5 && node.aboutMattress5.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling5" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress5.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling5" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress6 && node.aboutMattress6.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling6" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress6.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling6" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress7 && node.aboutMattress7.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling7" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress7.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling7" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress8 && node.aboutMattress8.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling8" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress8.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling8" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress9 && node.aboutMattress9.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling9" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress9.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling9" className="read-more-button"></label>
							            	</>
										)}
										{node.aboutMattress10 && node.aboutMattress10.childMarkdownRemark.headings[0].value === matres.name && (
							            	<>
								            	<input type="checkbox" id="toggling10" />
								            	<div className="read-more" dangerouslySetInnerHTML={{ __html: node.aboutMattress10.childMarkdownRemark.html }}/>
								            	<label htmlFor="toggling10" className="read-more-button"></label>
							            	</>
										)}
						            </div>
						            {matres.firmness && <div className="specifications flex">
						            	<div className="mb-[30px] md:mb-0">
						            		<p className="title-spec">Жесткость</p>
						            		<div className="image"><img src={firmness} alt="Жесткость" loading="lazy" width="80" height="73" /></div>
						            		<p>{matres.firmness}</p>
						            	</div>
						            	<div className="mb-[30px] md:mb-0">
						            		<p className="title-spec">Максимальная нагрузка</p>
						            		<div className="image"><img src={load} alt="Максимальная нагрузка" loading="lazy" width="80" height="73" /></div>
						            		<p>{matres.load === 'без ограничений' ? matres.load : ('до '+matres.load+' кг')}</p>
						            	</div>
						            	<div>
						            		<p className="title-spec">Количество пружин</p>
						            		<div className="image"><img src={springs} alt="Количество пружин" loading="lazy" width="80" height="73" /></div>
						            		<p>{matres.springs !== 'беспружинный' ? matres.springs.replace('пружин', '') + ' пружин' : 'беспружинный'}</p>
						            	</div>
						            	<div>
						            		<p className="title-spec">Гарантия</p>
						            		<div className="image"><img src={warranty} alt="Гарантия" loading="lazy" width="80" height="73" /></div>
						            		<p>{matres.warranty}</p>
						            	</div>
						            </div>}
						            {matres.brand && node.category !== 'Бренд' && matres.brand.linkBrandPage && <div className="link-block flex">
						            	<a href={'/'+matres.brand.linkBrandPage+'/'}>{'Все лучшие матрасы '+matres.brand.name}</a> 							
						            </div>}						        
						        </div>
					        </div>
							)
						))}
					</div>
					{/*<div id="yandex_rtb_C-A-690338-4"></div>*/}
				    {data.allContentfulPagesMattress.edges.map(({ node }) => (
				    	<div key={node.id} className="text_content text-lg font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.text.childMarkdownRemark.html }}/>
				    ))}
				    {data.allContentfulPagesMattress.edges.map(({ node }) => (
				    	node.plus && (
						    <div key={node.id} className="plus-minus font-[merriweather]">
						    	<h2 className="opacity-0 h-0">Плюсы и минусы</h2>
		                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
		                            <div className="plus">
		                                <h4><i />Плюсы</h4>
										<div dangerouslySetInnerHTML={{ __html: node.plus.childMarkdownRemark.html }}/>
									</div>
									<div className="minus">
										<h4><i />Минусы</h4>
										<div dangerouslySetInnerHTML={{ __html: node.minus.childMarkdownRemark.html }}/>
		                            </div>
		                        </div>
		                	</div>
	                	)
                	))}
                	{/*<div id="yandex_rtb_C-A-690338-14"></div>*/}
                	<Share />
                	<Author />
				</div>
			</section>
			{faqs[0].faqTitle1 && (<section className="faq py-12 max-w-[540px] md:max-w-[750px] mx-auto px-4">
				<div className="container">
					<h2 id="faq">Вопрос–Ответ</h2>
					<div className="accordeon-custom" itemScope itemType="https://schema.org/FAQPage">
						<div className="accordeon-custom__items">
							{accordionData.map(({ title, content }) => (
								<Faq key={Math.random()} title={title} content={content} />
							))}
						</div>
					</div>
				</div>
			</section>)}
			<Otherpages otherArticles={otherArticles[0]} titlePage="рейтинги" />
            <Footer />
	    </>
    )
}

export const query = graphql`
  	query PagesMattress($slug: String!) {
    	allContentfulPagesMattress(filter: { url: { eq: $slug } }) {
      		edges {
	        	node {
	          		id
	          		url
	          		titlePage
	          		title
	          		description
	          		category
	          		updatedAt
	          		titleTop
	          		showList
	          		minitext {
	          			childMarkdownRemark {
		            		html
		        		}
	          		}
	          		text {
	          			childMarkdownRemark {
		            		html
		            		headings(depth: h2) {
				                value
				                id
				            }
		        		}
	          		}
	          		plus {
	          			childMarkdownRemark {
		            		html
		        		}
	          		}
	          		minus {
	          			childMarkdownRemark {
		            		html
		        		}
	          		}
	          		faqTitle1
			        faqTitle2
			        faqTitle3
			        faqTitle4
			        faqTitle5
			        faqTitle6
			        faqTitle7
			        faqText1 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText2 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText3 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText4 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText5 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText6 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText7 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        otherArticles {
			        	__typename
            			... on ContentfulPagesMattress {
            				id
			          		url
			          		titlePage
            			}
			        }
			        aboutMattress1 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress2 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress3 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress4 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress5 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress6 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress7 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress8 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress9 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutMattress10 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        mattress {
			        	__typename
            			... on ContentfulMattress {
            				id
            				name
            				firmness
            				load
            				springs
            				warranty
            				affilateLink
            				linkPrice
            				marketId
            				url
            				peculiarities {
            					childMarkdownRemark {
				            		html
				        		}
            				}
            				image {
					            file {
					              	url
					            }
					            gatsbyImageData(
					              	layout: FIXED
					              	quality: 100
					              	width: 330
					            )
					        }
            				brand {
	            				__typename
	            				... on ContentfulBrand {
	            					id
            						name
            						linkBrandPage
            						image {
							            file {
							              	url
							            }
							            gatsbyImageData(
							              	layout: FIXED
							              	quality: 100
							            )
							        }
	            				}
            				}
            			}
			        }
	        	}
	      	}
    	}
  	}
`